import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import UserSettings from "../Interfaces/UserSettings";
import autoBindReact from "auto-bind/react";

interface UserSettingsDialogProps {
  open: boolean;
  userSettings: UserSettings;

  handleClose(): void;

  handleUpdateUserSettings(userSettings: UserSettings): void;
}

interface UserSettingsDialogState {
  paymentCutoff: number;
}

class UserSettingsDialog extends React.Component<
  UserSettingsDialogProps,
  UserSettingsDialogState
> {
  constructor(props: UserSettingsDialogProps) {
    super(props);

    this.state = {
      paymentCutoff: this.props.userSettings.paymentCutoff,
    };
    autoBindReact(this);
  }

  handleChangePaymentCutoff(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ paymentCutoff: Number(event.target.value) });
  }

  handleUpdateUserSettings() {
    this.handleClose();
    this.props.handleUpdateUserSettings({
      ...this.props.userSettings,
      paymentCutoff: this.state.paymentCutoff,
    });
  }

  handleClose() {
    this.props.handleClose();
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Settings</DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            id="paymentCutoff"
            label="Payment Cutoff"
            type="number"
            value={this.state.paymentCutoff}
            onChange={this.handleChangePaymentCutoff}
            fullWidth
            color="secondary"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handleUpdateUserSettings} color="secondary">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default UserSettingsDialog;

import React from "react";
import { createStyles, Theme } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import withTheme from "@material-ui/core/styles/withTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  withSnackbar,
} from "notistack";
import OneOffPaymentsTable from "./OneOffPaymentsTable/OneOffPaymentsTable";
import { Interval } from "luxon";
import OneOffPayment from "../Interfaces/OneOffPayment";
import autoBindReact from "auto-bind/react";

const useStyles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    markPaid: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.main,
    },
    table: {
      minWidth: 650,
    },
    actions: {
      marginBottom: "1rem",
      "& .MuiFormGroup-root": {
        justifyContent: "flex-end",
      },
    },
    card: {
      marginTop: 40,
      borderRadius: theme.spacing(0.5),
      transition: "0.3s",
      width: "90%",
      overflow: "initial",
      background: "#ffffff",
    },
    content: {
      paddingTop: 0,
      textAlign: "left",
      overflowX: "auto",
      "& table": {
        marginBottom: 0,
      },
    },
    subheading: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
  });

interface OneOffPaymentsProps {
  oneOffPayments: { [timestamp: number]: OneOffPayment[] };
  interval: Interval;

  handleTogglePaidOneOff(oneOffPayment: OneOffPayment): void;

  handleAddNewOneOffPayment(
    oneOffPayment: OneOffPayment
  ): Promise<OneOffPayment>;

  handleDeleteOneOffPayment(oneOffPayment: OneOffPayment): Promise<void>;

  classes: {
    root: string;
    markPaid: string;
    table: string;
    actions: string;
    card: string;
    content: string;
    subheading: string;
  };
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey;
  closeSnackbar: (key?: SnackbarKey) => void;
}

interface OneOffPaymentsState {
  hidePaid: boolean;
}

class OneOffPayments extends React.Component<
  OneOffPaymentsProps,
  OneOffPaymentsState
> {
  constructor(props: OneOffPaymentsProps) {
    super(props);
    this.state = {
      hidePaid: true,
    };

    autoBindReact(this);
  }

  handleHidePaid(event: { target: { checked: boolean } }): void {
    this.setState({
      hidePaid: event.target.checked,
    });
  }

  getFlatOneOffPayments(): OneOffPayment[] {
    let ret: OneOffPayment[] = [];

    // eslint-disable-next-line
    for (const [date, payments] of Object.entries(this.props.oneOffPayments)) {
      ret = ret.concat(payments);
    }

    return ret;
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Card>
          <CardHeader
            title=""
            subtitle=""
            action={
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.hidePaid}
                      onChange={this.handleHidePaid}
                      name="hide-paid"
                    />
                  }
                  label="Hide Paid"
                />
              </FormGroup>
            }
          />
          <CardContent className={classes.content}>
            <Typography
              variant="h5"
              color="primary"
              className={classes.subheading}
            >
              One Off Payments (
              {
                this.getFlatOneOffPayments().filter(
                  (payment: OneOffPayment) => payment.paid
                ).length
              }
              /{this.getFlatOneOffPayments().length} Paid)
            </Typography>
            <OneOffPaymentsTable
              interval={this.props.interval}
              oneOffPayments={this.props.oneOffPayments}
              hidePaid={this.state.hidePaid}
              handleTogglePaid={this.props.handleTogglePaidOneOff}
              handleAddNewOneOffPayment={this.props.handleAddNewOneOffPayment}
              handleDeleteOneOffPayment={this.props.handleDeleteOneOffPayment}
            />
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withSnackbar(withTheme(withStyles(useStyles)(OneOffPayments)));

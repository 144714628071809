import { firestore } from "../Firebase";
import {
  QueryDocumentSnapshot,
  QuerySnapshot,
} from "@firebase/firestore-types";
import { Interval, DateTime } from "luxon";
import OneOffPayment from "../Interfaces/OneOffPayment";

class OneOffPaymentRepository {
  interval: Interval;

  constructor(interval: Interval) {
    this.interval = interval;
  }

  add(oneOffPayment: OneOffPayment, group: string): Promise<OneOffPayment> {
    const { id, ...payment } = oneOffPayment;

    return firestore
      .collection(`groups/${group}/oneoffpayments`)
      .add(payment)
      .then((doc) => {
        return {
          ...payment,
          id: doc.id,
        };
      });
  }

  delete(oneOffPayment: OneOffPayment, group: string): Promise<void> {
    return firestore
      .collection(`groups/${group}/oneoffpayments`)
      .doc(oneOffPayment.id)
      .delete();
  }

  markPaid(
    oneOffPayment: OneOffPayment,
    group: string
  ): Promise<OneOffPayment> {
    return this.updateOneOffPayment(oneOffPayment, group, {
      paid: true,
    }).then(
      (): OneOffPayment => {
        return {
          ...oneOffPayment,
          paid: true,
        };
      }
    );
  }

  markUnpaid(
    oneOffPayment: OneOffPayment,
    group: string
  ): Promise<OneOffPayment> {
    return this.updateOneOffPayment(oneOffPayment, group, {
      paid: false,
    }).then(
      (): OneOffPayment => {
        return {
          ...oneOffPayment,
          paid: false,
        };
      }
    );
  }

  updateOneOffPayment(
    oneOffPayment: OneOffPayment,
    group: string,
    data: { [fieldPath: string]: any }
  ): Promise<void> {
    const paymentRef = firestore
      .collection(`groups/${group}/oneoffpayments`)
      .doc(oneOffPayment.id);
    return paymentRef.update(data);
  }

  getPaymentsInInterval(
    group: string,
    callback: (oneOffPayments: { [timestamp: number]: OneOffPayment[] }) => void
  ): void {
    firestore
      .collection(`groups/${group}/oneoffpayments`)
      .orderBy("date")
      .where("date", ">=", this.interval.start.toJSDate())
      .where("date", "<=", this.interval.end.toJSDate())
      .onSnapshot((querySnapshot: QuerySnapshot) => {
        let ret: {
          [timestamp: number]: OneOffPayment[];
        } = {};

        querySnapshot.forEach((doc: QueryDocumentSnapshot) => {
          const data = doc.data();
          const date: number = DateTime.fromJSDate(data.date.toDate())
            .startOf("day")
            .toSeconds();

          if (!(date in ret)) {
            ret[date] = [];
          }

          ret[date].push({
            id: doc.id,
            name: data.name,
            amount: data.amount,
            date: data.date,
            paid: data.paid,
          });
        });

        callback(ret);
      });
  }
}

export default OneOffPaymentRepository;

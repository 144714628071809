import { firestore, Timestamp } from "../Firebase";
import {
  QueryDocumentSnapshot,
  QuerySnapshot,
} from "@firebase/firestore-types";
import EnrichedPayment from "../Interfaces/EnrichedPayment";
import { DateTime, Interval } from "luxon";
import Payment from "../Interfaces/Payment";
import FirestorePayment from "../Interfaces/FirestorePayment";
import AccountRepository from "./AccountRepository";
import Account from "../Interfaces/Account";

class RecurringPaymentRepository {
  accountsRepo: AccountRepository;
  interval: Interval;

  constructor(interval: Interval, accountsRepo: AccountRepository) {
    this.interval = interval;
    this.accountsRepo = accountsRepo;
  }

  convertPaymentToEnrichedPayment(
    payment: Payment,
    id?: string
  ): EnrichedPayment {
    if (id === undefined) {
      id = this.convertNameToId(payment.name);
    }

    return {
      ...payment,
      id: id,
      dueThisPeriod: this.interval.contains(payment.nextDue),
      paidThisPeriod: this.interval.contains(payment.lastPaid),
    };
  }

  convertEnrichedPaymentToFirestorePayment(
    payment: EnrichedPayment
  ): FirestorePayment {
    return {
      name: payment.name,
      account: payment.account.id,
      amount: payment.amount,
      dayDue: payment.dayDue,
      lastPaid: Timestamp.fromDate(payment.lastPaid.toJSDate()),
      nextDue: Timestamp.fromDate(payment.nextDue.toJSDate()),
      archived: payment.archived,
      category: payment.category,
    };
  }

  convertNameToId(name: string): string {
    return name.replace(/\s+/g, "-").toLowerCase();
  }

  add(payment: EnrichedPayment, group: string): Promise<EnrichedPayment> {
    const id = this.convertNameToId(payment.name);

    return firestore
      .collection(`groups/${group}/payments`)
      .doc(id)
      .set(this.convertEnrichedPaymentToFirestorePayment(payment))
      .then(() => {
        return this.convertPaymentToEnrichedPayment(payment, id);
      });
  }

  markPaid(payment: EnrichedPayment, group: string): Promise<EnrichedPayment> {
    let nextDue = DateTime.local().set({ day: payment.dayDue });
    let lastPaid = DateTime.local().set({ day: payment.dayDue });

    if (this.interval.contains(nextDue)) {
      nextDue = nextDue.plus({ months: 1 });
    }
    if (!this.interval.contains(lastPaid)) {
      lastPaid = lastPaid.minus({ months: 1 });
    }

    return this.updatePayment(payment, group, {
      lastPaid: Timestamp.fromDate(lastPaid.toJSDate()),
      nextDue: Timestamp.fromDate(nextDue.toJSDate()),
    }).then(
      (): EnrichedPayment => {
        return {
          ...payment,
          lastPaid: lastPaid,
          nextDue: nextDue,
          dueThisPeriod: this.interval.contains(nextDue),
          paidThisPeriod: this.interval.contains(lastPaid),
        };
      }
    );
  }

  markUnpaid(
    payment: EnrichedPayment,
    group: string
  ): Promise<EnrichedPayment> {
    let nextDue = DateTime.local().set({ day: payment.dayDue });
    let lastPaid = DateTime.local()
      .set({ day: payment.dayDue })
      .minus({ months: 1 });

    if (!this.interval.contains(nextDue)) {
      nextDue = nextDue.minus({ months: 1 });
    }
    if (this.interval.contains(lastPaid)) {
      lastPaid = lastPaid.minus({ months: 1 });
    }

    return this.updatePayment(payment, group, {
      lastPaid: Timestamp.fromDate(lastPaid.toJSDate()),
      nextDue: Timestamp.fromDate(nextDue.toJSDate()),
    }).then(
      (): EnrichedPayment => {
        return {
          ...payment,
          lastPaid: lastPaid,
          nextDue: nextDue,
          dueThisPeriod: this.interval.contains(nextDue),
          paidThisPeriod: this.interval.contains(lastPaid),
        };
      }
    );
  }

  archivePayment(payment: EnrichedPayment, group: string) {
    return this.updatePayment(payment, group, {
      archived: true,
    }).then(
      (): EnrichedPayment => {
        return {
          ...payment,
          archived: true,
        };
      }
    );
  }

  updateNotes(payment: EnrichedPayment, group: string) {
    return this.updatePayment(payment, group, {
      notes: payment.notes,
    }).then(
      (): EnrichedPayment => {
        return {
          ...payment,
          notes: payment.notes,
        };
      }
    );
  }

  updateCategory(payment: EnrichedPayment, group: string) {
    return this.updatePayment(payment, group, {
      category: payment.category,
    }).then(
      (): EnrichedPayment => {
        return {
          ...payment,
          category: payment.category,
        };
      }
    );
  }

  updatePayment(
    payment: EnrichedPayment,
    group: string,
    data: { [fieldPath: string]: any }
  ): Promise<void> {
    const paymentRef = firestore
      .collection(`groups/${group}/payments`)
      .doc(payment.id);
    return paymentRef.update(data);
  }

  getAccountPayments(
    group: string,
    accounts: Account[],
    paymentCutoff: number,
    callback: (accountPayments: {
      [accountId: string]: { [paymentId: string]: EnrichedPayment };
    }) => void
  ): void {
    firestore
      .collection(`groups/${group}/payments`)
      .orderBy("dayDue")
      .where("archived", "==", false)
      .onSnapshot((querySnapshot: QuerySnapshot) => {
        let preCutoff: EnrichedPayment[] = [];
        let postCutoff: EnrichedPayment[] = [];

        querySnapshot.forEach((doc: QueryDocumentSnapshot) => {
          const data = doc.data();
          const nextDue = DateTime.fromJSDate(data.nextDue.toDate()).startOf(
            "day"
          );
          const lastPaid = DateTime.fromJSDate(data.lastPaid.toDate()).startOf(
            "day"
          );

          const account = this.accountsRepo.getFirstAccountWithDefault(
            accounts.filter((account: Account) => account.id === data.account)
          );

          let enrichedPayment = this.convertPaymentToEnrichedPayment(
            {
              name: data.name,
              account: account,
              amount: data.amount,
              dayDue: data.dayDue,
              nextDue: nextDue,
              lastPaid: lastPaid,
              archived: data.archived ?? false,
              notes: data.notes ?? [],
              category: data.category ?? "default",
            },
            doc.id
          );

          if (
            this.interval.contains(enrichedPayment.nextDue) ||
            this.interval.contains(enrichedPayment.lastPaid)
          ) {
            if (enrichedPayment.dayDue < paymentCutoff) {
              preCutoff.push(enrichedPayment);
            }
            if (enrichedPayment.dayDue >= paymentCutoff) {
              postCutoff.push(enrichedPayment);
            }
          } else {
            console.log("Skipping payment", enrichedPayment);
          }
        });

        let accountPayments: {
          [accountId: string]: { [paymentId: string]: EnrichedPayment };
        } = {};
        postCutoff.concat(preCutoff).forEach((payment: EnrichedPayment) => {
          if (!(payment.account.id in accountPayments)) {
            accountPayments[payment.account.id] = {};
          }
          accountPayments[payment.account.id][payment.id] = payment;
        });

        callback(accountPayments);
      });
  }
}

export default RecurringPaymentRepository;

const formatters = {
  formatPoundAmount: function (value: number): string {
    return new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(value);
  },
  capitalise: function (value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  },
};

export default formatters;

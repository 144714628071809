import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { DateTime } from "luxon";
import OneOffPayment from "../Interfaces/OneOffPayment";
import autoBindReact from "auto-bind/react";
import firebase from "firebase";

interface NewOneOffPaymentDialogProps {
  open: boolean;
  date: DateTime;

  handleClose(): void;

  handleAddNewOneOffPayment(
    oneOffPayment: OneOffPayment
  ): Promise<OneOffPayment>;
}

interface NewOneOffPaymentDialogState extends OneOffPayment {}

class NewOneOffPaymentDialog extends React.Component<
  NewOneOffPaymentDialogProps,
  NewOneOffPaymentDialogState
> {
  constructor(props: NewOneOffPaymentDialogProps) {
    super(props);

    this.state = this.getDefaultState();

    autoBindReact(this);
  }

  getDefaultState(): NewOneOffPaymentDialogState {
    return {
      id: "",
      name: "",
      amount: 0,
      date: firebase.firestore.Timestamp.fromDate(this.props.date.toJSDate()),
      paid: false,
    };
  }

  handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ name: event.target.value });
  }

  handleChangeAmount(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ amount: Number(event.target.value) });
  }

  handleAddPayment() {
    this.props
      .handleAddNewOneOffPayment(this.state)
      .then((oneOffPayment: OneOffPayment) => {
        this.setState(this.getDefaultState());
      });
  }

  handleClose() {
    this.setState(this.getDefaultState());
    this.props.handleClose();
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Add a new one off payment (
          {this.props.date.toLocaleString(DateTime.DATE_HUGE)})
        </DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            id="name"
            label="Name"
            type="text"
            value={this.state.name}
            onChange={this.handleChangeName}
            fullWidth
            color="secondary"
          />
          <TextField
            id="amount"
            label="Amount"
            type="number"
            value={this.state.amount}
            onChange={this.handleChangeAmount}
            fullWidth
            color="secondary"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handleAddPayment} color="secondary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default NewOneOffPaymentDialog;

import React from "react";
import {
  createStyles,
  CssBaseline,
  Theme,
  withStyles,
  withTheme,
} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";

const useStyles = (theme: Theme) =>
  createStyles({
    root: {},
  });

interface LoadingProps {
  classes: {
    root: string;
  };
}

interface LoadingState {}

class Loader extends React.Component<LoadingProps, LoadingState> {
  render() {
    return (
      <div className={this.props.classes.root}>
        <CssBaseline />
        <Container maxWidth="xl">
          <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            style={{ height: "100vh" }}
          >
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default withTheme(withStyles(useStyles)(Loader));

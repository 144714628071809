import React from "react";
import { auth } from "../Firebase";
import {
  AppBar,
  Avatar,
  createStyles,
  CssBaseline,
  Menu,
  MenuItem,
  Theme,
} from "@material-ui/core";
import { User } from "@firebase/auth-types";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Payments from "./Payments";
import OneOffPayments from "./OneOffPayments";
import PaymentsSummary from "./PaymentsSummary";
import Account from "../Interfaces/Account";
import NewRecurringPaymentDialog from "./NewRecurringPaymentDialog";
import Payment from "../Interfaces/Payment";
import EnrichedPayment from "../Interfaces/EnrichedPayment";
import Fab from "@material-ui/core/Fab";
import withTheme from "@material-ui/core/styles/withTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccountRepository from "../Data/AccountRepository";
import RecurringPaymentRepository from "../Data/RecurringPaymentRepository";
import CurrentInterval from "../Data/CurrentInterval";
import { Interval } from "luxon";
import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  withSnackbar,
} from "notistack";
import Hidden from "@material-ui/core/Hidden";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import UserSettings from "../Interfaces/UserSettings";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import UserSettingsDialog from "./UserSettingsDialog";
import SettingsRoundedIcon from "@material-ui/icons/SettingsRounded";
import sw_types from "../Interfaces/ServiceWorker";
import OneOffPaymentRepository from "../Data/OneOffPaymentsRepository";
import autoBindReact from "auto-bind/react";
import OneOffPayment from "../Interfaces/OneOffPayment";

const useStyles = (theme: Theme) =>
  createStyles({
    root: {},
    logo: {
      flexGrow: 1,
    },
    offset: {
      minHeight: theme.spacing(3),
    },
    fab: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    interval: {
      marginRight: theme.spacing(2),
    },
    accordion: {
      marginBottom: theme.spacing(5),
    },
    subheading: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
    content: {
      paddingTop: 0,
      textAlign: "left",
      overflowX: "auto",
      "& table": {
        marginBottom: 0,
      },
    },
  });

interface MainPageProps {
  user: User;

  setDarkMode(enabled: boolean): Promise<void>;

  handleUpdateUserSettings(userSettings: UserSettings): Promise<void>;

  userSettings: UserSettings;

  swStatus: { status: number; payload: any };

  classes: {
    root: string;
    logo: string;
    offset: string;
    fab: string;
    interval: string;
    accordion: string;
    subheading: string;
    content: string;
  };

  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey;
  closeSnackbar: (key?: SnackbarKey) => void;
}

interface MainPageState {
  avatarMenuAnchor: (EventTarget & Element) | null;
  accounts: Account[];
  newRecurringPaymentDialogOpen: boolean;
  userSettingsDialogOpen: boolean;
  accountPayments: {
    [accountId: string]: { [paymentId: string]: EnrichedPayment };
  };
  oneOffPayments: { [timestamp: number]: OneOffPayment[] };
  expanded: string;
  interval: Interval;
}

class MainPage extends React.Component<MainPageProps, MainPageState> {
  recurringPaymentRepo: RecurringPaymentRepository;
  oneOffPaymentRepo: OneOffPaymentRepository;
  accountRepo: AccountRepository;

  constructor(props: MainPageProps) {
    super(props);
    this.state = {
      avatarMenuAnchor: null,
      accounts: [],
      accountPayments: {},
      oneOffPayments: {},
      newRecurringPaymentDialogOpen: false,
      userSettingsDialogOpen: false,
      expanded: "summary",
      interval: new CurrentInterval().get(
        this.props.userSettings.paymentCutoff
      ),
    };

    this.accountRepo = new AccountRepository();
    this.recurringPaymentRepo = new RecurringPaymentRepository(
      this.state.interval,
      this.accountRepo
    );
    this.oneOffPaymentRepo = new OneOffPaymentRepository(this.state.interval);

    autoBindReact(this);
  }

  componentDidMount() {
    this.accountRepo.all(this.props.userSettings.group, (accounts) => {
      this.setState({
        accounts: accounts,
        // expanded: this.accountRepo.getFirstAccountWithDefault(accounts).id,
      });

      this.recurringPaymentRepo.getAccountPayments(
        this.props.userSettings.group,
        accounts,
        this.props.userSettings.paymentCutoff,
        (accountPayments) => this.setState({ accountPayments: accountPayments })
      );

      this.oneOffPaymentRepo.getPaymentsInInterval(
        this.props.userSettings.group,
        (oneOffPayments) => this.setState({ oneOffPayments: oneOffPayments })
      );
    });

    console.log(this.props.swStatus);
    if (this.props.swStatus.status === sw_types.SW_INIT) {
      this.props.enqueueSnackbar("Successfully cached page", {
        variant: "success",
      });
    }
  }

  handleShowMenu(event: React.MouseEvent) {
    this.setState({ avatarMenuAnchor: event.currentTarget });
  }

  handleCloseMenu() {
    this.setState({ avatarMenuAnchor: null });
  }

  handleLogout() {
    this.handleCloseMenu();
    auth.signOut();
  }

  toggleDarkMode() {
    this.handleCloseMenu();
    this.props
      .setDarkMode(!this.props.userSettings.prefersDarkMode)
      .catch((err: Error) => {
        this.props.enqueueSnackbar("Error: " + err.message, {
          variant: "error",
        });
      });
  }

  showUserSettings() {
    this.handleCloseMenu();

    this.setState({ userSettingsDialogOpen: true });
  }

  handleChangeAccordion(panel: string) {
    return (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      this.setState({ expanded: isExpanded ? panel : "" });
    };
  }

  handleUpdateUserSettings(userSettings: UserSettings): void {
    this.props
      .handleUpdateUserSettings(userSettings)
      .then(() => {
        this.setState({
          interval: new CurrentInterval().get(userSettings.paymentCutoff),
        });
        this.recurringPaymentRepo = new RecurringPaymentRepository(
          this.state.interval,
          this.accountRepo
        );
      })
      .then(() => {
        this.props.enqueueSnackbar("Successfully updated user settings", {
          variant: "success",
        });
      })
      .catch((err: Error) => {
        this.props.enqueueSnackbar("Error: " + err.message, {
          variant: "error",
        });
      });
  }

  handleAddNewOneOffPayment(
    oneOffPayment: OneOffPayment
  ): Promise<OneOffPayment> {
    return this.oneOffPaymentRepo.add(
      oneOffPayment,
      this.props.userSettings.group
    );
  }

  handleDeleteOneOffPayment(oneOffPayment: OneOffPayment): Promise<void> {
    return this.oneOffPaymentRepo.delete(
      oneOffPayment,
      this.props.userSettings.group
    );
  }

  handleAddNewPayment(payment: Payment): Promise<EnrichedPayment> {
    const enrichedPayment: EnrichedPayment = this.recurringPaymentRepo.convertPaymentToEnrichedPayment(
      payment
    );

    this.updatePaymentState(enrichedPayment);

    this.setState({
      newRecurringPaymentDialogOpen: false,
    });

    return this.recurringPaymentRepo.add(
      enrichedPayment,
      this.props.userSettings.group
    );
  }

  handleTogglePaid(payment: EnrichedPayment): void {
    if (payment.paidThisPeriod) {
      this.recurringPaymentRepo
        .markUnpaid(payment, this.props.userSettings.group)
        .then((payment: EnrichedPayment) => {
          this.props.enqueueSnackbar(
            `Successfully marked payment ${payment.name} unpaid`,
            {
              variant: "success",
            }
          );
          return payment;
        });
    } else {
      this.recurringPaymentRepo
        .markPaid(payment, this.props.userSettings.group)
        .then((payment: EnrichedPayment) => {
          this.props.enqueueSnackbar(
            `Successfully marked payment ${payment.name} paid`,
            {
              variant: "success",
            }
          );
          return payment;
        });
    }
  }

  handleTogglePaidOneOff(oneOffPayment: OneOffPayment): void {
    if (oneOffPayment.paid) {
      this.oneOffPaymentRepo
        .markUnpaid(oneOffPayment, this.props.userSettings.group)
        .then((oneOffPayment: OneOffPayment) => {
          this.props.enqueueSnackbar(
            `Successfully marked one off payment ${oneOffPayment.name} unpaid`,
            {
              variant: "success",
            }
          );
          return oneOffPayment;
        });
    } else {
      this.oneOffPaymentRepo
        .markPaid(oneOffPayment, this.props.userSettings.group)
        .then((oneOffPayment: OneOffPayment) => {
          this.props.enqueueSnackbar(
            `Successfully marked one off payment ${oneOffPayment.name} paid`,
            {
              variant: "success",
            }
          );
          return oneOffPayment;
        });
    }
  }

  handleArchivePayment(payment: EnrichedPayment) {
    payment.archived = true;
    this.updatePaymentState(payment);

    this.recurringPaymentRepo
      .archivePayment(payment, this.props.userSettings.group)
      .then((payment: EnrichedPayment) => {
        this.props.enqueueSnackbar(
          `Successfully archived payment ${payment.name}`,
          {
            variant: "success",
          }
        );
        return payment;
      });
  }

  handleChangeCategory(payment: EnrichedPayment, category: string) {
    payment.category = category;
    this.updatePaymentState(payment);

    this.recurringPaymentRepo
      .updateCategory(payment, this.props.userSettings.group)
      .then((payment: EnrichedPayment) => {
        this.props.enqueueSnackbar(
          `Successfully updated payment category for ${payment.name}`,
          {
            variant: "success",
          }
        );
        return payment;
      });
  }

  updatePaymentState(payment: EnrichedPayment) {
    let accountPayments = this.state.accountPayments;
    if (!(payment.account.id in accountPayments)) {
      accountPayments[payment.account.id] = {};
    }

    accountPayments[payment.account.id][payment.id] = payment;

    this.setState({
      accountPayments: accountPayments,
    });
  }

  handleAddNote(payment: EnrichedPayment, note: string) {
    payment.notes.push(note);
    this.updatePaymentState(payment);

    this.recurringPaymentRepo
      .updateNotes(payment, this.props.userSettings.group)
      .then((payment: EnrichedPayment) => {
        this.props.enqueueSnackbar(
          `Successfully added note to payment ${payment.name}`,
          {
            variant: "success",
          }
        );
        return payment;
      });
  }

  handleDeleteNote(payment: EnrichedPayment, noteId: number) {
    payment.notes.splice(noteId, 1);
    this.updatePaymentState(payment);

    this.recurringPaymentRepo
      .updateNotes(payment, this.props.userSettings.group)
      .then((payment: EnrichedPayment) => {
        this.props.enqueueSnackbar(
          `Successfully deleted note from payment ${payment.name}`,
          {
            variant: "success",
          }
        );
        return payment;
      });
  }

  updateServiceWorker() {
    const registrationWaiting = this.props.swStatus.payload.waiting;

    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });

      registrationWaiting.addEventListener("statechange", (e: any) => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  }

  render() {
    const { classes, user } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar position="sticky" color="primary">
          <Toolbar>
            <Typography className={classes.logo}>
              <Hidden xsDown>
                <img
                  style={{ height: "4rem" }}
                  src="img/logo.png"
                  alt="phinance"
                />
              </Hidden>
              <Hidden smUp>
                <img
                  style={{ height: "1.5rem" }}
                  src="img/logo-sm.png"
                  alt="phinance"
                />
              </Hidden>
            </Typography>
            <Hidden xsDown>
              <Typography className={classes.interval}>
                Current Period: {this.state.interval.toFormat("DDDD")}
              </Typography>
            </Hidden>

            {this.props.swStatus.status === sw_types.SW_UPDATED && (
              <Typography>
                Content updated.{" "}
                <Button variant="contained" onClick={this.updateServiceWorker}>
                  Refresh
                </Button>
              </Typography>
            )}

            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={this.handleShowMenu}
            >
              <Avatar
                title={user.displayName ?? ""}
                alt={user.displayName ?? ""}
                src={user.photoURL ?? ""}
              />
            </IconButton>
            <Menu
              id="avatar-menu"
              anchorEl={this.state.avatarMenuAnchor}
              keepMounted
              open={Boolean(this.state.avatarMenuAnchor)}
              onClose={this.handleCloseMenu}
            >
              <MenuItem onClick={this.toggleDarkMode}>
                <ListItemIcon>
                  <Brightness4Icon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    this.props.userSettings.prefersDarkMode
                      ? "Light Mode"
                      : "Dark Mode"
                  }
                />
              </MenuItem>
              <MenuItem onClick={this.showUserSettings}>
                <ListItemIcon>
                  <SettingsRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </MenuItem>
              <MenuItem onClick={this.handleLogout}>
                <ListItemIcon>
                  <ExitToAppIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>
          </Toolbar>
        </AppBar>
        <div className={classes.offset} />

        <Container maxWidth="xl">
          <Accordion
            key="summary"
            expanded={this.state.expanded === "summary"}
            onChange={this.handleChangeAccordion("summary")}
            className={classes.accordion}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h4">Summary</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <PaymentsSummary
                user={this.props.user}
                accounts={this.state.accounts}
                accountPayments={this.state.accountPayments}
                oneOffPayments={this.state.oneOffPayments}
                interval={this.state.interval}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            key="one-off"
            expanded={this.state.expanded === "one-off"}
            onChange={this.handleChangeAccordion("one-off")}
            className={classes.accordion}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="h4">One Off Payments</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <OneOffPayments
                oneOffPayments={this.state.oneOffPayments}
                interval={this.state.interval}
                handleAddNewOneOffPayment={this.handleAddNewOneOffPayment}
                handleDeleteOneOffPayment={this.handleDeleteOneOffPayment}
                handleTogglePaidOneOff={this.handleTogglePaidOneOff}
              />
            </AccordionDetails>
          </Accordion>

          {this.state.accounts.map((account: Account) => {
            return (
              <Accordion
                key={account.id}
                expanded={this.state.expanded === account.id}
                onChange={this.handleChangeAccordion(account.id)}
                className={classes.accordion}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h4">{account.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* {account.id in this.state.accountPayments ? ( */}
                  <Payments
                    user={this.props.user}
                    userSettings={this.props.userSettings}
                    account={account}
                    payments={
                      account.id in this.state.accountPayments
                        ? Object.values(this.state.accountPayments[account.id])
                        : []
                    }
                    oneOffPayments={this.state.oneOffPayments}
                    interval={this.state.interval}
                    handleTogglePaid={this.handleTogglePaid}
                    handleTogglePaidOneOff={this.handleTogglePaidOneOff}
                    handleArchivePayment={this.handleArchivePayment}
                    handleAddNote={this.handleAddNote}
                    handleDeleteNote={this.handleDeleteNote}
                    handleChangeCategory={this.handleChangeCategory}
                    handleAddNewOneOffPayment={this.handleAddNewOneOffPayment}
                    handleDeleteOneOffPayment={this.handleDeleteOneOffPayment}
                  />
                  {/* ) : (
                    "No Payments Found"
                  )} */}
                </AccordionDetails>
              </Accordion>
            );
          })}

          <NewRecurringPaymentDialog
            open={this.state.newRecurringPaymentDialogOpen}
            handleClose={() =>
              this.setState({ newRecurringPaymentDialogOpen: false })
            }
            handleAddNewPayment={this.handleAddNewPayment}
            accounts={this.state.accounts}
          />

          <UserSettingsDialog
            open={this.state.userSettingsDialogOpen}
            handleClose={() => this.setState({ userSettingsDialogOpen: false })}
            handleUpdateUserSettings={this.handleUpdateUserSettings}
            userSettings={this.props.userSettings}
          />

          <Fab
            color="primary"
            aria-label="add"
            className={classes.fab}
            onClick={() =>
              this.setState({ newRecurringPaymentDialogOpen: true })
            }
          >
            <AddRoundedIcon />
          </Fab>
        </Container>
      </div>
    );
  }
}

export default withSnackbar(withTheme(withStyles(useStyles)(MainPage)));

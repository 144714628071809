import { firestore } from "../Firebase";
import {
  QueryDocumentSnapshot,
  QuerySnapshot,
} from "@firebase/firestore-types";
import Account from "../Interfaces/Account";

class AccountRepository {
  getFirstAccountWithDefault(accounts: Account[]): Account {
    let account = [...accounts].shift();
    if (account === undefined) {
      return this.getEmptyAccount();
    }

    return account;
  }

  getEmptyAccount(): Account {
    return {
      id: "",
      availableBalance: 0,
      name: "",
      order: 0,
    };
  }

  all(group: string, callback: (accounts: Account[]) => void): void {
    firestore
      .collection(`groups/${group}/accounts`)
      .orderBy("order")
      .onSnapshot((querySnapshot: QuerySnapshot) => {
        let accounts: Account[] = [];
        querySnapshot.forEach((doc: QueryDocumentSnapshot) => {
          const data = doc.data();

          const account: Account = {
            id: doc.id,
            availableBalance: Number(data.availableBalance),
            name: data.name,
            order: data.order,
          };

          accounts.push(account);
        });

        callback(accounts);
      });
  }
}

export default AccountRepository;

import React, { ReactNode } from "react";
import { auth } from "../Firebase";
import { User } from "@firebase/auth-types";
import UserSettings from "../Interfaces/UserSettings";
import UserSettingRepository from "../Data/UserSettingRepository";
import autoBindReact from "auto-bind/react";

interface UserProviderProps {
  children: ReactNode;
}

interface UserProviderState {
  userContext: UserContextInterface | null;
}

interface UserContextInterface {
  user: User | null;
  userSettings: UserSettings;

  updateUserSettings(userSettings: UserSettings): void;
}

export const UserContext = React.createContext<UserContextInterface | null>(
  null
);

class UserProvider extends React.Component<
  UserProviderProps,
  UserProviderState
> {
  private userSettingsRepository: UserSettingRepository;

  constructor(props: UserProviderProps) {
    super(props);
    this.userSettingsRepository = new UserSettingRepository();
    this.state = { userContext: null };
    autoBindReact(this);
  }

  componentDidMount = () => {
    auth.onAuthStateChanged((user: User | null) => {
      if (user) {
        this.userSettingsRepository
          .get(user.uid)
          .then((userSettings: UserSettings) => {
            const userContext: UserContextInterface = {
              user: user,
              userSettings: userSettings,
              updateUserSettings: this.updateUserSettings,
            };
            this.setState({ userContext: userContext });
          });
        return;
      }

      const userContext: UserContextInterface = {
        user: user,
        userSettings: this.userSettingsRepository.getDefault(),
        updateUserSettings: this.updateUserSettings,
      };
      this.setState({ userContext: userContext });
    });
  };

  updateUserSettings(userSettings: UserSettings): Promise<void> {
    if (!this.state.userContext) {
      return new Promise((resolve, reject) => {
        reject(new Error("User context not set"));
      });
    }

    const { user } = this.state.userContext;
    if (user === null) {
      return new Promise((resolve, reject) => {
        reject(new Error("User not logged in"));
      });
    }
    this.setState({
      userContext: {
        user: user,
        userSettings: userSettings,
        updateUserSettings: this.updateUserSettings,
      },
    });

    return this.userSettingsRepository.update(user.uid, userSettings);
  }

  render() {
    return (
      <UserContext.Provider value={this.state.userContext}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export default UserProvider;

import { firestore } from "../Firebase";
import UserSettings from "../Interfaces/UserSettings";
import { DocumentSnapshot } from "@firebase/firestore-types";

class UserSettingRepository {
  get(userId: string): Promise<UserSettings> {
    const userRef = firestore.collection("users").doc(userId);

    return userRef.get().then((doc: DocumentSnapshot) => {
      if (doc.exists) {
        const data = doc.data();
        if (data) {
          const userSettings: UserSettings = {
            prefersDarkMode: data.prefersDarkMode,
            paymentCutoff: data.paymentCutoff,
            group: data.group,
          };
          return userSettings;
        }
      }
      return this.getDefault();
    });
  }

  update(userId: string, userSettings: UserSettings): Promise<void> {
    const userRef = firestore.collection("users").doc(userId);

    return userRef.update(userSettings);
  }

  getDefault(): UserSettings {
    return {
      prefersDarkMode: window.matchMedia("(prefers-color-scheme: dark)")
        .matches,
      paymentCutoff: 30,
      group: "",
    };
  }
}

export default UserSettingRepository;

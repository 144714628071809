import React, { ReactElement } from "react";
import clsx from "clsx";
import {
  createStyles,
  FormControl,
  InputLabel,
  List,
  Select,
  Switch,
  TableCell,
  TableRow,
  Theme,
} from "@material-ui/core";
import { DateTime } from "luxon";
import formatters from "../../Util/formatters";
import EnrichedPayment from "../../Interfaces/EnrichedPayment";
import Hidden from "@material-ui/core/Hidden";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import withTheme from "@material-ui/core/styles/withTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import NoteAddRoundedIcon from "@material-ui/icons/NoteAddRounded";
import NoteRoundedIcon from "@material-ui/icons/NoteRounded";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import MoreVertRoundedIcon from "@material-ui/icons/MoreVertRounded";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ArchiveRoundedIcon from "@material-ui/icons/ArchiveRounded";
import Badge from "@material-ui/core/Badge";
import CategoryRepository from "../../Data/CategoryRepository";
import autoBindReact from "auto-bind/react";

const useStyles = (theme: Theme) =>
  createStyles({
    notPaidThisPeriod: {
      backgroundColor: theme.palette.error.light,
      "& td, th": {
        fontWeight: 600,
      },
    },
    markPaid: {
      backgroundColor: theme.palette.success.dark,
      color: "#FFF",
    },
    archiveMenu: {
      color: theme.palette.warning.dark,
      "& .MuiSvgIcon-root": {
        color: theme.palette.warning.dark,
      },
    },
    notesIcon: {
      color: theme.palette.info.dark,
    },
  });

interface PaymentsTableRowProps {
  payment: EnrichedPayment;

  handleTogglePaid(payment: EnrichedPayment): void;

  handleArchivePayment(payment: EnrichedPayment): void;

  handleDeleteNote(payment: EnrichedPayment, noteId: number): void;

  handleAddNote(payment: EnrichedPayment, note: string): void;

  handleChangeCategory(payment: EnrichedPayment, category: string): void;

  classes: {
    notPaidThisPeriod: string;
    markPaid: string;
    archiveMenu: string;
    notesIcon: string;
  };
}

interface PaymentsTableRowState {
  rowMenuAnchor: (EventTarget & Element) | null;
  confirmArchiveOpen: boolean;
  notesOpen: boolean;
  categoryOpen: boolean;
  note: string;
  category: string;
}

class PaymentsTableRow extends React.Component<
  PaymentsTableRowProps,
  PaymentsTableRowState
> {
  categoryRepository = new CategoryRepository();

  constructor(props: PaymentsTableRowProps) {
    super(props);
    this.state = {
      rowMenuAnchor: null,
      confirmArchiveOpen: false,
      notesOpen: false,
      categoryOpen: false,
      note: "",
      category: props.payment.category,
    };

    autoBindReact(this);
  }

  renderDateHuman(date: DateTime): ReactElement {
    return (
      <span title={date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}>
        {date.toRelative()}
      </span>
    );
  }

  handleCloseMenu() {
    this.setState({ rowMenuAnchor: null });
  }

  handleCloseArchiveConfirm() {
    this.setState({ confirmArchiveOpen: false });
  }

  handleConfirmArchivePayment() {
    this.handleCloseMenu();
    this.setState({
      confirmArchiveOpen: true,
    });
  }

  handleArchivePayment() {
    this.handleCloseArchiveConfirm();
    this.props.handleArchivePayment(this.props.payment);
  }

  handleCloseSaveCategory() {
    this.props.handleChangeCategory(this.props.payment, this.state.category);
    this.setState({ categoryOpen: false });
  }

  handleShowCategory() {
    this.setState({ categoryOpen: true });
  }

  handleChangeCategory(event: React.ChangeEvent<{ value: unknown }>) {
    this.setState({
      category: String(event.target.value),
    });
  }

  handleCloseNotes() {
    this.setState({ notesOpen: false });
  }

  handleShowNotes() {
    this.handleCloseMenu();
    this.setState({ notesOpen: true });
  }

  handleAddNote() {
    this.props.handleAddNote(this.props.payment, this.state.note);

    this.setState({ note: "" });
  }

  handleDeleteNote(noteId: number) {
    return () => {
      this.props.handleDeleteNote(this.props.payment, noteId);
    };
  }

  render() {
    const { payment, classes } = this.props;
    const today = DateTime.local();

    return (
      <TableRow
        key={payment.name}
        className={clsx(
          !payment.paidThisPeriod &&
            payment.nextDue < today &&
            classes.notPaidThisPeriod
        )}
      >
        <Hidden xsDown>
          <TableCell component="th" scope="row">
            <IconButton size="small" onClick={this.handleShowCategory}>
              {this.categoryRepository.getIcon(payment.category)}
            </IconButton>
            <strong style={{ marginLeft: "1rem" }}>{payment.name}</strong>
          </TableCell>
          <TableCell>{formatters.formatPoundAmount(payment.amount)}</TableCell>
        </Hidden>
        <Hidden smUp>
          <TableCell>
            <IconButton size="small">
              {this.categoryRepository.getIcon(payment.category)}
            </IconButton>
          </TableCell>
          <TableCell component="th" scope="row">
            <strong>{payment.name}</strong>
            <br />
            {formatters.formatPoundAmount(payment.amount)}
            <br />
            Due {this.renderDateHuman(payment.nextDue)}
          </TableCell>
        </Hidden>
        <Hidden xsDown>
          <TableCell>{this.renderDateHuman(payment.nextDue)}</TableCell>
          <TableCell>{this.renderDateHuman(payment.lastPaid)}</TableCell>
        </Hidden>
        <TableCell>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Switch
                checked={payment.paidThisPeriod}
                onChange={() => this.props.handleTogglePaid(payment)}
                name="paid"
              />
            </Grid>
            <Grid item xs={6} style={{ textAlign: "right" }}>
              <IconButton
                onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                  this.setState({ rowMenuAnchor: event.currentTarget })
                }
              >
                <Badge
                  badgeContent={this.props.payment.notes.length}
                  color="primary"
                >
                  <MoreVertRoundedIcon />
                </Badge>
              </IconButton>
              <Menu
                id="simple-menu"
                anchorEl={this.state.rowMenuAnchor}
                keepMounted
                open={Boolean(this.state.rowMenuAnchor)}
                onClose={this.handleCloseMenu}
              >
                <MenuItem onClick={this.handleShowNotes}>
                  <ListItemIcon>
                    {
                      <Badge
                        badgeContent={this.props.payment.notes.length}
                        color="primary"
                      >
                        <NoteRoundedIcon />
                      </Badge>
                    }
                  </ListItemIcon>

                  <ListItemText primary="Notes" />
                </MenuItem>
                <MenuItem
                  onClick={this.handleConfirmArchivePayment}
                  className={this.props.classes.archiveMenu}
                >
                  <ListItemIcon>
                    <ArchiveRoundedIcon />
                  </ListItemIcon>
                  <ListItemText primary="Archive" />
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </TableCell>

        <Dialog
          open={this.state.confirmArchiveOpen}
          onClose={this.handleCloseArchiveConfirm}
        >
          <DialogContent>
            <DialogContentText>
              {`Are you sure you want to archive the ${payment.name} payment?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseArchiveConfirm} color="primary">
              Cancel
            </Button>
            <Button
              onClick={this.handleArchivePayment}
              color="primary"
              autoFocus
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.notesOpen}
          onClose={this.handleCloseNotes}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle>Notes</DialogTitle>
          <List>
            {this.props.payment.notes && this.props.payment.notes.length > 0 ? (
              this.props.payment.notes.map((note: string, key: number) => {
                return (
                  <ListItem divider key={key}>
                    <ListItemText primary={note} />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={this.handleDeleteNote(key)}
                      >
                        <DeleteOutlineRoundedIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })
            ) : (
              <ListItem divider>
                <ListItemText primary=" No Notes Found" />
              </ListItem>
            )}
            <ListItem divider>
              <ListItemText
                primary={
                  <TextField
                    margin="normal"
                    label="Add Note"
                    type="text"
                    fullWidth
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      this.setState({ note: event.target.value })
                    }
                  />
                }
              />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={this.handleAddNote}>
                  <NoteAddRoundedIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
          <DialogActions>
            <Button onClick={this.handleCloseNotes} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={this.state.categoryOpen}
          onClose={this.handleCloseSaveCategory}
        >
          <DialogTitle>Set Category</DialogTitle>
          <DialogContent>
            <FormControl style={{ minWidth: "100%" }}>
              <InputLabel id="category">Category</InputLabel>
              <Select
                labelId="category"
                value={this.state.category}
                onChange={this.handleChangeCategory}
                color="secondary"
              >
                {this.categoryRepository
                  .getCategories()
                  .map((category: string) => {
                    return (
                      <MenuItem key={category} value={category}>
                        {formatters.capitalise(category)}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseSaveCategory} color="primary">
              Save &amp; Close
            </Button>
          </DialogActions>
        </Dialog>
      </TableRow>
    );
  }
}

export default withTheme(withStyles(useStyles)(PaymentsTableRow));

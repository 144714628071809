import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { createStyles, Grid, Theme } from "@material-ui/core";
import withTheme from "@material-ui/core/styles/withTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import EnrichedPayment from "../../Interfaces/EnrichedPayment";
import {
  Chart,
  Legend,
  PieSeries,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { Animation, EventTracker } from "@devexpress/dx-react-chart";
import formatters from "../../Util/formatters";
import CategoryRepository from "../../Data/CategoryRepository";
import { schemePaired } from "d3-scale-chromatic";
import { Palette } from "@devexpress/dx-react-chart";
import autoBindReact from "auto-bind/react";

const useStyles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
  });

interface BreakdownPieChartProps {
  payments: EnrichedPayment[];
  classes: {
    paper: string;
  };
}

interface BreakdownPieChartState {}

class BreakdownPieChart extends React.Component<
  BreakdownPieChartProps,
  BreakdownPieChartState
> {
  formattedData: { argument: string; value: number }[] = [];
  categoryRepository = new CategoryRepository();

  constructor(props: BreakdownPieChartProps) {
    super(props);
    autoBindReact(this);
  }

  getChartData() {
    let categoryPayments: { [id: string]: number } = {};
    this.props.payments.forEach((payment: EnrichedPayment) => {
      if (!(payment.category in categoryPayments)) {
        categoryPayments[payment.category] = 0;
      }

      categoryPayments[payment.category] += payment.amount;
    });
    console.log(categoryPayments);
    return categoryPayments;
  }

  getChartFormatted() {
    if (this.formattedData.length > 0) {
      return this.formattedData;
    }

    for (let [key, value] of Object.entries(this.getChartData())) {
      this.formattedData.push({
        argument: formatters.capitalise(key),
        value: value,
      });
    }
    return this.formattedData;
  }

  getTooltip(data: Tooltip.ContentProps) {
    const title = this.getChartFormatted()[data.targetItem.point].argument;

    return (
      <div>
        <Grid container direction="row" alignItems="center">
          <Grid item>{this.categoryRepository.getIcon(title)}</Grid>
          <Grid item>&nbsp;{title}</Grid>
        </Grid>

        <span>{formatters.formatPoundAmount(Number(data.text))}</span>
      </div>
    );
  }

  render() {
    return (
      <Paper className={clsx(this.props.classes.paper)}>
        <Typography variant="h5" color="secondary">
          Category Breakdown
        </Typography>
        <Chart data={this.getChartFormatted()} height={350}>
          <Animation />
          <Legend />
          <EventTracker />
          <Palette scheme={schemePaired} />
          <Tooltip contentComponent={this.getTooltip} />
          <PieSeries
            innerRadius={0.5}
            valueField="value"
            argumentField="argument"
          />
        </Chart>
      </Paper>
    );
  }
}

export default withTheme(withStyles(useStyles)(BreakdownPieChart));

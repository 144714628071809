import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { FormControl } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { DateTime } from "luxon";
import Payment from "../Interfaces/Payment";
import Account from "../Interfaces/Account";
import EnrichedPayment from "../Interfaces/EnrichedPayment";
import AccountRepository from "../Data/AccountRepository";
import autoBindReact from "auto-bind/react";

interface NewRecurringPaymentDialogProps {
  open: boolean;
  accounts: Account[];

  handleClose(): void;

  handleAddNewPayment(payment: Payment): Promise<EnrichedPayment>;
}

interface NewRecurringPaymentDialogState extends Payment {}

class NewRecurringPaymentDialog extends React.Component<
  NewRecurringPaymentDialogProps,
  NewRecurringPaymentDialogState
> {
  accountRepo = new AccountRepository();

  constructor(props: NewRecurringPaymentDialogProps) {
    super(props);

    this.state = this.getDefaultState();

    autoBindReact(this);
  }

  getDefaultState(): NewRecurringPaymentDialogState {
    return {
      name: "",
      account: this.accountRepo.getFirstAccountWithDefault(this.props.accounts),
      amount: 0,
      dayDue: DateTime.local().day,
      lastPaid: DateTime.local().minus({ months: 1 }),
      nextDue: DateTime.local().plus({ months: 1 }),
      archived: false,
      notes: [],
      category: "default",
    };
  }

  getAccount(accountId: string): Account {
    return this.accountRepo.getFirstAccountWithDefault(
      this.props.accounts.filter((account: Account) => {
        return account.id === accountId;
      })
    );
  }

  handleChangeName(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ name: event.target.value });
  }

  handleChangeAccount(event: React.ChangeEvent<{ value: unknown }>) {
    this.setState({ account: this.getAccount(String(event.target.value)) });
  }

  handleChangeAmount(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({ amount: Number(event.target.value) });
  }

  handleChangeDayDue(event: React.ChangeEvent<HTMLInputElement>) {
    let dayDue = Number(event.target.value);

    this.setState({
      dayDue: dayDue,
      lastPaid: this.state.lastPaid.set({ day: dayDue }),
      nextDue: this.state.nextDue.set({ day: dayDue }),
    });
  }

  handleChangeLastPaid(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      lastPaid: DateTime.fromFormat(event.target.value, "yyyy-MM-dd"),
    });
  }

  handleChangeNextDue(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      nextDue: DateTime.fromFormat(event.target.value, "yyyy-MM-dd"),
    });
  }

  handleAddPayment() {
    this.props
      .handleAddNewPayment(this.state)
      .then((payment: EnrichedPayment) => {
        this.setState(this.getDefaultState());
      });
  }

  handleClose() {
    this.setState(this.getDefaultState());
    this.props.handleClose();
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Add a new recurring payment
        </DialogTitle>

        <DialogContent>
          <TextField
            autoFocus
            id="name"
            label="Name"
            type="text"
            value={this.state.name}
            onChange={this.handleChangeName}
            fullWidth
            color="secondary"
          />
          <FormControl style={{ minWidth: "100%" }}>
            <InputLabel id="account">Account</InputLabel>
            <Select
              labelId="account"
              value={this.state.account.id}
              onChange={this.handleChangeAccount}
              color="secondary"
            >
              {this.props.accounts.map((account: Account) => {
                return (
                  <MenuItem key={account.id} value={account.id}>
                    {account.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <TextField
            id="amount"
            label="Amount"
            type="number"
            value={this.state.amount}
            onChange={this.handleChangeAmount}
            fullWidth
            color="secondary"
          />
          <TextField
            id="dayDue"
            label="Day Due"
            type="number"
            value={this.state.dayDue}
            onChange={this.handleChangeDayDue}
            fullWidth
            color="secondary"
          />
          <TextField
            id="lastPaid"
            label="Last Paid"
            type="date"
            value={this.state.lastPaid.toFormat("yyyy-MM-dd")}
            onChange={this.handleChangeLastPaid}
            fullWidth
            color="secondary"
          />
          <TextField
            id="nextDue"
            label="Next Due"
            type="date"
            value={this.state.nextDue.toFormat("yyyy-MM-dd")}
            onChange={this.handleChangeNextDue}
            fullWidth
            color="secondary"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={this.handleAddPayment} color="secondary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default NewRecurringPaymentDialog;

import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { createStyles, Theme } from "@material-ui/core";
import withTheme from "@material-ui/core/styles/withTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import EnrichedPayment from "../../Interfaces/EnrichedPayment";
import {
  Chart,
  Legend,
  PieSeries,
  Tooltip,
} from "@devexpress/dx-react-chart-material-ui";
import { Animation, EventTracker } from "@devexpress/dx-react-chart";
import formatters from "../../Util/formatters";
import { schemePaired } from "d3-scale-chromatic";
import { Palette } from "@devexpress/dx-react-chart";
import autoBindReact from "auto-bind/react";

const useStyles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
  });

interface PaidVsUnpaidPieChartProps {
  payments: EnrichedPayment[];
  classes: {
    paper: string;
  };
}

interface PaidVsUnpaidPieChartState {}

class PaidVsUnpaidPieChart extends React.Component<
  PaidVsUnpaidPieChartProps,
  PaidVsUnpaidPieChartState
> {
  formattedData: { argument: string; value: number }[] = [];

  constructor(props: PaidVsUnpaidPieChartProps) {
    super(props);
    autoBindReact(this);
  }

  getChartData() {
    let paidUnpaid: { [id: string]: number } = {
      paid: 0,
      unpaid: 0,
    };

    this.props.payments.forEach((payment: EnrichedPayment) => {
      let key = "unpaid";
      if (payment.paidThisPeriod) {
        key = "paid";
      }
      paidUnpaid[key] += payment.amount;
    });

    return paidUnpaid;
  }

  getChartFormatted() {
    if (this.formattedData.length > 0) {
      return this.formattedData;
    }

    for (let [key, value] of Object.entries(this.getChartData())) {
      this.formattedData.push({
        argument: formatters.capitalise(key),
        value: value,
      });
    }
    return this.formattedData;
  }

  getTooltip(data: Tooltip.ContentProps) {
    const title = this.getChartFormatted()[data.targetItem.point].argument;

    return (
      <div>
        <strong>{title}</strong>
        <br />
        <span>{formatters.formatPoundAmount(Number(data.text))}</span>
      </div>
    );
  }

  render() {
    return (
      <Paper className={clsx(this.props.classes.paper)}>
        <Typography variant="h5" color="secondary">
          Paid Vs Unpaid
        </Typography>
        <Chart data={this.getChartFormatted()} height={350}>
          <Animation />
          <Legend />
          <EventTracker />
          <Tooltip contentComponent={this.getTooltip} />
          <PieSeries
            innerRadius={0.5}
            valueField="value"
            argumentField="argument"
          />
          <Palette scheme={schemePaired} />
        </Chart>
      </Paper>
    );
  }
}

export default withTheme(withStyles(useStyles)(PaidVsUnpaidPieChart));

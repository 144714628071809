import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { createStyles, Theme } from "@material-ui/core";
import withTheme from "@material-ui/core/styles/withTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";

const useStyles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      "& .summaryValue": {
        minHeight: theme.spacing(7),
        margin: 0,
        padding: 0,
      },
      "& .MuiTypography-body1": {
        fontSize: "3rem",
        margin: 0,
        padding: 0,
        lineHeight: 1,
      },
    },
    warning: {
      backgroundColor: theme.palette.warning.main,
      "& .MuiTypography-h5": {
        color: "#303030",
      },
      "& .summaryValue": {
        color: "#303030",
      },
    },
  });

interface SummaryCardProps {
  title: string;
  highlightWarning?: boolean;
  classes: {
    paper: string;
    warning: string;
  };
}

interface SummaryCardState {}

class SummaryCard extends React.Component<SummaryCardProps, SummaryCardState> {
  render() {
    return (
      <Paper
        className={clsx(
          this.props.classes.paper,
          this.props.highlightWarning && this.props.classes.warning
        )}
      >
        <Typography variant="h5" color="secondary">
          {this.props.title}
        </Typography>
        <Grid
          className={clsx(this.props.classes.paper, "summaryValue")}
          container
          direction="row"
          justify="flex-end"
          alignItems="flex-end"
        >
          {this.props.children}
        </Grid>
      </Paper>
    );
  }
}

export default withTheme(withStyles(useStyles)(SummaryCard));

import React from "react";
import {
  createStyles,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Theme,
  Paper,
  withStyles,
  withTheme,
  TableContainer,
  Hidden,
} from "@material-ui/core";
import { DateTime, Interval } from "luxon";
import OneOffPayment from "../../Interfaces/OneOffPayment";
import OneOffPaymentsTableRow from "./OneOffPaymentsTableRow";

const useStyles = (theme: Theme) =>
  createStyles({
    root: {},
    table: {
      //minWidth: 650,
      "& .MuiTableCell-head": {
        fontWeight: 600,
      },
    },
  });

interface OneOffPaymentsTableProps {
  classes: {
    root: string;
    table: string;
  };
  interval: Interval;
  oneOffPayments: { [timestamp: number]: OneOffPayment[] };
  hidePaid: boolean;

  handleTogglePaid(oneOffpayment: OneOffPayment): void;

  handleAddNewOneOffPayment(
    oneOffPayment: OneOffPayment
  ): Promise<OneOffPayment>;

  handleDeleteOneOffPayment(oneOffPayment: OneOffPayment): Promise<void>;
}

interface OneOffPaymentsTableState {}

class OneOffPaymentsTable extends React.Component<
  OneOffPaymentsTableProps,
  OneOffPaymentsTableState
> {
  getPayments(dt: DateTime): OneOffPayment[] {
    const key = dt.toSeconds();

    return key in this.props.oneOffPayments
      ? this.props.oneOffPayments[key]
      : [];
  }

  getRows() {
    let dt = this.props.interval.start.startOf("day");
    let today = DateTime.now().startOf("day");

    let rows = [];
    while (dt < this.props.interval.end) {
      const oneOffPayments = this.getPayments(dt);

      const unpaidPayments = oneOffPayments.filter(
        (oneOffPayment: OneOffPayment) => {
          return !oneOffPayment.paid;
        }
      );

      if (dt < today && unpaidPayments.length === 0 && this.props.hidePaid) {
        dt = dt.plus({ days: 1 });
        continue;
      }

      rows.push(
        <OneOffPaymentsTableRow
          key={dt.toISO()}
          datetime={dt}
          oneOffPayments={oneOffPayments}
          handleTogglePaid={this.props.handleTogglePaid}
          hidePaid={this.props.hidePaid}
          handleAddNewOneOffPayment={this.props.handleAddNewOneOffPayment}
          handleDeleteOneOffPayment={this.props.handleDeleteOneOffPayment}
        />
      );
      dt = dt.plus({ days: 1 });
    }
    return rows;
  }

  render() {
    return (
      <TableContainer component={Paper} className={this.props.classes.root}>
        <Table className={this.props.classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <Hidden xsDown>
                <TableCell style={{ minWidth: "10%" }}>Date</TableCell>
                <TableCell style={{ minWidth: "80%" }}>Payments</TableCell>
                <TableCell style={{ minWidth: "5%" }}>Total Amount</TableCell>
                <TableCell style={{ minWidth: "5%" }}></TableCell>
              </Hidden>
              <Hidden smUp>
                <TableCell style={{ minWidth: "100%" }}>Payments</TableCell>
              </Hidden>
            </TableRow>
          </TableHead>
          <TableBody>{this.getRows()}</TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default withTheme(withStyles(useStyles)(OneOffPaymentsTable));

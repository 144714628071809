import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { createStyles, Theme } from "@material-ui/core";
import withTheme from "@material-ui/core/styles/withTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import EnrichedPayment from "../../Interfaces/EnrichedPayment";
import {
  ArgumentAxis,
  BarSeries,
  Chart,
  LineSeries,
  Tooltip,
  ValueAxis,
} from "@devexpress/dx-react-chart-material-ui";
import { Animation, EventTracker } from "@devexpress/dx-react-chart";
import { DateTime, Interval } from "luxon";
import formatters from "../../Util/formatters";
import { schemePaired } from "d3-scale-chromatic";
import { Palette } from "@devexpress/dx-react-chart";
import autoBindReact from "auto-bind/react";

const useStyles = (theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
    },
  });

interface MonthToViewChartProps {
  payments: EnrichedPayment[];
  interval: Interval;
  classes: {
    paper: string;
  };
}

interface MonthToViewChartState {}

class MonthToViewChart extends React.Component<
  MonthToViewChartProps,
  MonthToViewChartState
> {
  formattedData: { argument: string; value: number; today: number }[] = [];

  constructor(props: MonthToViewChartProps) {
    super(props);
    autoBindReact(this);
  }

  getChartData() {
    const days: { day: number; value: number }[] = [];

    this.props.interval.splitBy({ days: 1 }).forEach((d) => {
      days.push({
        day: d.start.day,
        value: 0,
      });
    });
    days.push({
      day: this.props.interval.end.day,
      value: 0,
    });

    let paymentDays: { [id: number]: number } = {};
    this.props.payments.forEach((payment) => {
      if (!(payment.dayDue in paymentDays)) {
        paymentDays[payment.dayDue] = 0;
      }
      paymentDays[payment.dayDue] += payment.amount;
    });

    days.forEach((day) => {
      if (day.day in paymentDays) {
        day.value = paymentDays[day.day];
      }
    });

    return days;
  }

  getChartFormatted() {
    if (this.formattedData.length > 0) {
      return this.formattedData;
    }

    var now = DateTime.local();

    this.formattedData = this.getChartData().map((day) => {
      return {
        argument: day.day.toString(),
        value: day.value,
        today: Number(day.day) === now.day ? 800 : 0,
      };
    });

    return this.formattedData;
  }

  getTooltip(data: Tooltip.ContentProps) {
    const title = this.getChartFormatted()[data.targetItem.point].argument;

    return (
      <div>
        <strong>{title}</strong>
        <br />
        <span>{formatters.formatPoundAmount(Number(data.text))}</span>
      </div>
    );
  }

  render() {
    return (
      <Paper className={clsx(this.props.classes.paper)}>
        <Typography variant="h5" color="secondary">
          Month To View
        </Typography>
        <Chart data={this.getChartFormatted()} height={250}>
          <ArgumentAxis />
          <ValueAxis />
          <Animation />
          <EventTracker />
          <Tooltip contentComponent={this.getTooltip} />
          <LineSeries valueField="value" argumentField="argument" />
          <BarSeries valueField="today" argumentField="argument" />
          <Palette scheme={schemePaired} />
        </Chart>
      </Paper>
    );
  }
}

export default withTheme(withStyles(useStyles)(MonthToViewChart));

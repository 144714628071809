import { DateTime, Interval } from "luxon";

class CurrentInterval {
  interval: Interval | null = null;

  get(paymentCutoff: number): Interval {
    if (this.interval === null) {
      const dt = DateTime.local();
      let startMonth = dt.month;
      let endMonth = dt.month;

      let startYear = dt.year;
      let endYear = dt.year;

      if (dt.day < paymentCutoff) {
        startMonth--;
      }

      if (dt.day >= paymentCutoff) {
        endMonth++;
      }

      let startPaymentCutoff = paymentCutoff;
      let endPaymentCutoff = paymentCutoff - 1;

      const endOfMonth = DateTime.local(startYear, startMonth, 1).endOf(
        "month"
      );

      if (startPaymentCutoff > endOfMonth.day) {
        startPaymentCutoff = 1;
        startMonth++;
      }

      if (startMonth <= 0) {
        startMonth = 12 + startMonth;
        startYear--;
      }

      if (endMonth <= 0) {
        endMonth = 11 + endMonth;
      }

      if (endMonth > 12) {
        endMonth = endMonth - 12;
        endYear++;
      }

      let startDate = DateTime.local(
        startYear,
        startMonth,
        startPaymentCutoff,
        0,
        0,
        0
      );
      let endDate = DateTime.local(
        endYear,
        endMonth,
        endPaymentCutoff,
        23,
        59,
        59
      );

      this.interval = Interval.fromDateTimes(startDate, endDate);

      if (!this.interval.isValid) {
        console.error(
          "Invalid interval",
          "Start",
          startDate.toJSDate(),
          "End",
          endDate.toJSDate()
        );
      }
    }

    return this.interval;
  }
}

export default CurrentInterval;

import React from "react";
import {
  createStyles,
  TableCell,
  TableRow,
  Theme,
  Table,
  TableBody,
  Switch,
} from "@material-ui/core";
import { DateTime } from "luxon";
import withTheme from "@material-ui/core/styles/withTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import CategoryRepository from "../../Data/CategoryRepository";
import OneOffPayment from "../../Interfaces/OneOffPayment";
import formatters from "../../Util/formatters";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import NewOneOffPaymentDialog from "../NewOneOffPaymentDialog";
import autoBindReact from "auto-bind/react";
import clsx from "clsx";
import Hidden from "@material-ui/core/Hidden";

const useStyles = (theme: Theme) =>
  createStyles({
    notPaid: {
      backgroundColor: theme.palette.error.light,
      "& td, th": {
        fontWeight: 600,
      },
    },
    markPaid: {
      backgroundColor: theme.palette.success.dark,
      color: "#FFF",
    },
    weekendrow: {
      backgroundColor: theme.palette.info.light,
    },
    todayrow: {
      backgroundColor: theme.palette.info.dark,
    },
  });

interface OneOffPaymentsTableRowProps {
  handleTogglePaid(oneOffPayment: OneOffPayment): void;

  handleAddNewOneOffPayment(
    oneOffPayment: OneOffPayment
  ): Promise<OneOffPayment>;

  handleDeleteOneOffPayment(oneOffPayment: OneOffPayment): Promise<void>;

  classes: {
    notPaid: string;
    markPaid: string;
    weekendrow: string;
    todayrow: string;
  };

  datetime: DateTime;
  oneOffPayments: OneOffPayment[];
  hidePaid: boolean;
}

interface OneOffPaymentsTableRowState {
  newOneOffPaymentDialogOpen: boolean;
}

class OneOffPaymentsTableRow extends React.Component<
  OneOffPaymentsTableRowProps,
  OneOffPaymentsTableRowState
> {
  categoryRepository = new CategoryRepository();

  constructor(props: OneOffPaymentsTableRowProps) {
    super(props);
    this.state = {
      newOneOffPaymentDialogOpen: false,
    };
    autoBindReact(this);
  }

  handleAddNewOneOffPayment(
    oneOffPayment: OneOffPayment
  ): Promise<OneOffPayment> {
    return this.props
      .handleAddNewOneOffPayment(oneOffPayment)
      .then((oneOffPayment: OneOffPayment) => {
        this.setState({ newOneOffPaymentDialogOpen: false });

        return oneOffPayment;
      });
  }

  isWeekend() {
    return (
      this.props.datetime.weekday === 6 || this.props.datetime.weekday === 7
    );
  }

  isToday() {
    return this.props.datetime.equals(DateTime.now().startOf("day"));
  }

  getPaymentsTable() {
    if (this.props.oneOffPayments.length === 0) {
      return (
        <Hidden xsDown>
          <span>-</span>
        </Hidden>
      );
    }

    return (
      <Table>
        <TableBody>
          {this.props.oneOffPayments.map((payment: OneOffPayment) => {
            if (this.props.hidePaid && payment.paid) {
              return null;
            }

            return (
              <TableRow key={payment.id}>
                <Hidden xsDown>
                  <TableCell component="th" width="50%">
                    {payment.name}
                  </TableCell>
                  <TableCell width="40%">
                    {formatters.formatPoundAmount(payment.amount)}
                  </TableCell>
                  <TableCell width="5%">
                    <Switch
                      checked={payment.paid}
                      onChange={() => this.props.handleTogglePaid(payment)}
                      name="paid"
                    />
                  </TableCell>
                  <TableCell width="5%">
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        this.props.handleDeleteOneOffPayment(payment)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </Hidden>
                <Hidden smUp>
                  <TableCell component="th" width="50%">
                    {payment.name}
                    <br />
                    {formatters.formatPoundAmount(payment.amount)}
                  </TableCell>

                  <TableCell width="5%">
                    <Switch
                      checked={payment.paid}
                      onChange={() => this.props.handleTogglePaid(payment)}
                      name="paid"
                    />
                  </TableCell>
                  <TableCell width="5%">
                    <IconButton
                      aria-label="delete"
                      onClick={() =>
                        this.props.handleDeleteOneOffPayment(payment)
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </Hidden>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    );
  }

  render() {
    return (
      <TableRow
        className={clsx(
          this.isWeekend() && this.props.classes.weekendrow,
          this.isToday() && this.props.classes.todayrow
        )}
      >
        <TableCell component="th" scope="row">
          <Hidden xsDown>
            <strong>
              {this.props.datetime.toLocaleString(DateTime.DATE_HUGE)}
            </strong>
          </Hidden>

          <Hidden smUp>
            <IconButton
              aria-label="add"
              onClick={() =>
                this.setState({ newOneOffPaymentDialogOpen: true })
              }
            >
              <AddIcon />
            </IconButton>
            <strong>
              {this.props.datetime.toLocaleString(DateTime.DATE_SHORT)}
            </strong>
            {this.getPaymentsTable()}
          </Hidden>
        </TableCell>

        <Hidden xsDown>
          <TableCell>{this.getPaymentsTable()}</TableCell>

          <TableCell>
            {formatters.formatPoundAmount(
              this.props.oneOffPayments
                .filter((oneOffPayment: OneOffPayment) => !oneOffPayment.paid)
                .reduce(
                  (accumulator: number, oneOffPayment: OneOffPayment) =>
                    accumulator + oneOffPayment.amount,
                  0
                )
            )}
          </TableCell>
          <TableCell align="right">
            <IconButton
              aria-label="add"
              onClick={() =>
                this.setState({ newOneOffPaymentDialogOpen: true })
              }
            >
              <AddIcon />
            </IconButton>
          </TableCell>
        </Hidden>

        <NewOneOffPaymentDialog
          open={this.state.newOneOffPaymentDialogOpen}
          handleClose={() =>
            this.setState({ newOneOffPaymentDialogOpen: false })
          }
          handleAddNewOneOffPayment={this.handleAddNewOneOffPayment}
          date={this.props.datetime}
        />
      </TableRow>
    );
  }
}

export default withTheme(withStyles(useStyles)(OneOffPaymentsTableRow));

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

var config = {
  apiKey: "AIzaSyCtSTJT1pWzzEd1ZllQhYQTlcqXRjFS0gU",
  authDomain: "philwc-dev-phinance.firebaseapp.com",
  databaseURL: "https://philwc-dev-phinance.firebaseio.com",
  projectId: "philwc-dev-phinance",
  storageBucket: "philwc-dev-phinance.appspot.com",
  messagingSenderId: "64785884035",
  appId: "1:64785884035:web:d3ad59212d7b5b8cf38cb6",
  measurementId: "G-V14DB75SSW",
};

firebase.initializeApp(config);

const fs = firebase.firestore();
fs.enablePersistence();

export const firestore = fs;
export const auth = firebase.auth();
export const Timestamp = firebase.firestore.Timestamp;

const provider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => {
  auth.signInWithPopup(provider);
};

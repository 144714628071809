import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ErrorBoundary from "./ErrorBoundary";
import UserProvider from "./Providers/UserProvider";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import sw_types from "./Interfaces/ServiceWorker";

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

let sw_status = { status: sw_types.SW_NONE, payload: {} };

ReactDOM.render(
  <ErrorBoundary>
    <UserProvider>
      <App swStatus={sw_status} />
    </UserProvider>
  </ErrorBoundary>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onSuccess: () =>
    (sw_status = {
      status: sw_types.SW_INIT,
      payload: {},
    }),
  onUpdate: (registration) =>
    (sw_status = {
      status: sw_types.SW_UPDATED,
      payload: registration,
    }),
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

import React from "react";
import { createStyles, Grid, Hidden, Theme } from "@material-ui/core";
import { User } from "@firebase/auth-types";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import formatters from "../Util/formatters";
import withStyles from "@material-ui/core/styles/withStyles";
import SummaryCard from "./SummaryCard";
import Typography from "@material-ui/core/Typography";
import BreakdownPieChart from "./Charts/BreakdownPieChart";
import MonthToViewChart from "./Charts/MonthToViewChart";
import { Interval } from "luxon";
import PaidVsUnpaidPieChart from "./Charts/PaidVsUnpaidPieChart";
import OneOffPayment from "../Interfaces/OneOffPayment";
import autoBindReact from "auto-bind/react";
import EnrichedPayment from "../Interfaces/EnrichedPayment";
import Account from "../Interfaces/Account";
import withTheme from "@material-ui/core/styles/withTheme";

const useStyles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    content: {
      paddingTop: 0,
      textAlign: "left",
      overflowX: "auto",
      "& table": {
        marginBottom: 0,
      },
    },
  });

interface PaymentsSummaryProps {
  user: User;
  accounts: Account[];
  accountPayments: {
    [accountId: string]: { [paymentId: string]: EnrichedPayment };
  };
  oneOffPayments: { [timestamp: number]: OneOffPayment[] };
  interval: Interval;

  classes: {
    root: string;
    content: string;
  };
}

interface PaymentsSummaryState {}

class PaymentsSummary extends React.Component<
  PaymentsSummaryProps,
  PaymentsSummaryState
> {
  totalAvailableBalance: number = -1;
  payments: EnrichedPayment[] = [];
  oneOffPayments: OneOffPayment[] = [];
  constructor(props: PaymentsSummaryProps) {
    super(props);

    this.state = {};

    autoBindReact(this);
  }

  getFlatOneOffPayments(): OneOffPayment[] {
    if (this.oneOffPayments.length === 0) {
      // eslint-disable-next-line
      for (const [date, payments] of Object.entries(
        this.props.oneOffPayments
      )) {
        this.oneOffPayments = this.oneOffPayments.concat(payments);
      }
    }
    return this.oneOffPayments;
  }

  getTotalAvailableBalance(): number {
    return this.props.accounts.reduce(
      (accumulator: number, account: Account) => {
        return accumulator + account.availableBalance;
      },
      0
    );
  }

  getFlatPayments(): EnrichedPayment[] {
    if (this.payments.length === 0) {
      // eslint-disable-next-line
      for (const [accountId, payments] of Object.entries(
        this.props.accountPayments
      )) {
        this.payments = this.payments.concat(Object.values(payments));
      }
    }

    return this.payments;
  }

  getTotal(): number {
    return this.getRecurringTotal() + this.getOneOffTotal();
  }

  getRecurringTotal(): number {
    return this.sumRecurringPaymentsAmount(this.getFlatPayments());
  }

  getOneOffTotal(): number {
    return this.sumOneOffPaymentsAmount(this.getFlatOneOffPayments());
  }

  getAvailable(): number {
    return this.getTotalAvailableBalance() - this.getTotalLeftToPay();
  }

  getAvailableNoOneOff(): number {
    return this.getTotalAvailableBalance() - this.getTotalRecurringLeftToPay();
  }

  getTotalLeftToPay(): number {
    return this.getTotalRecurringLeftToPay() + this.getTotalOneOffLeftToPay();
  }

  getTotalRecurringLeftToPay(): number {
    return this.sumRecurringPaymentsAmount(
      this.getFlatPayments().filter(
        (payment: EnrichedPayment) => !payment.paidThisPeriod
      )
    );
  }

  getTotalOneOffLeftToPay(): number {
    return this.sumOneOffPaymentsAmount(
      this.getFlatOneOffPayments().filter(
        (oneOffPayment: OneOffPayment) => !oneOffPayment.paid
      )
    );
  }

  sumRecurringPaymentsAmount(payments: EnrichedPayment[]) {
    return payments
      .map((payment: EnrichedPayment): number => payment.amount)
      .reduce((total: number, amount: number) => total + amount, 0);
  }

  sumOneOffPaymentsAmount(oneOffPayments: OneOffPayment[]) {
    return oneOffPayments
      .map((payment: OneOffPayment): number => payment.amount)
      .reduce((total: number, amount: number) => total + amount, 0);
  }

  render() {
    const { classes } = this.props;
    const payments = this.getFlatPayments();

    return (
      <div className={classes.root}>
        <Card>
          <CardHeader title="" subtitle="" />
          <CardContent className={classes.content}>
            <Grid
              container
              spacing={2}
              direction="row"
              justify="space-evenly"
              alignItems="center"
            >
              <Grid item xs={12} sm={6} lg={3}>
                <SummaryCard
                  title="Available To Spend"
                  highlightWarning={this.getAvailable() < 10}
                >
                  <Typography align="right">
                    {formatters.formatPoundAmount(this.getAvailable())}
                  </Typography>

                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h6" color="primary">
                        Without One Off
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ fontSize: "1.5em" }}
                        align="center"
                      >
                        {formatters.formatPoundAmount(
                          this.getAvailableNoOneOff()
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                <SummaryCard title="Left To Pay">
                  <Typography align="right">
                    {formatters.formatPoundAmount(this.getTotalLeftToPay())}
                  </Typography>

                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h6" color="primary">
                        Recurring
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ fontSize: "1.5em" }}
                        align="center"
                      >
                        {formatters.formatPoundAmount(
                          this.getTotalRecurringLeftToPay()
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" color="primary">
                        One Off
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ fontSize: "1.5em" }}
                        align="center"
                      >
                        {formatters.formatPoundAmount(
                          this.getTotalOneOffLeftToPay()
                        )}
                      </Typography>
                    </Grid>
                  </Grid>
                </SummaryCard>
              </Grid>

              <Grid item xs={12} sm={6} lg={3}>
                <SummaryCard title="Total Outgoings">
                  <Typography align="right">
                    {formatters.formatPoundAmount(this.getTotal())}
                  </Typography>

                  <Grid
                    container
                    spacing={2}
                    direction="row"
                    justify="space-evenly"
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography variant="h6" color="primary">
                        Recurring
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ fontSize: "1.5em" }}
                        align="center"
                      >
                        {formatters.formatPoundAmount(this.getRecurringTotal())}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" color="primary">
                        One Off
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ fontSize: "1.5em" }}
                        align="center"
                      >
                        {formatters.formatPoundAmount(this.getOneOffTotal())}
                      </Typography>
                    </Grid>
                  </Grid>
                </SummaryCard>
              </Grid>
            </Grid>
            <Hidden xsDown>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} lg={6}>
                  {payments.length > 0 && (
                    <BreakdownPieChart payments={payments} />
                  )}
                </Grid>

                <Grid item xs={12} sm={6} lg={6}>
                  {payments.length > 0 && (
                    <PaidVsUnpaidPieChart payments={payments} />
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} lg={12}>
                  {payments.length > 0 && (
                    <MonthToViewChart
                      payments={payments}
                      interval={this.props.interval}
                    />
                  )}
                </Grid>
              </Grid>
            </Hidden>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default withTheme(withStyles(useStyles)(PaymentsSummary));

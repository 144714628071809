import React, { ReactElement } from "react";
import { withStyles, withTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { createStyles, Theme } from "@material-ui/core";
import { DateTime } from "luxon";
import PaymentsTableRow from "./PaymentsTableRow";
import EnrichedPayment from "../../Interfaces/EnrichedPayment";
import Hidden from "@material-ui/core/Hidden";
import autoBindReact from "auto-bind/react";

const useStyles = (theme: Theme) =>
  createStyles({
    table: {
      //minWidth: 650,
      "& .MuiTableCell-head": {
        fontWeight: 600,
      },
    },
  });

interface PaymentsTableProps {
  payments: EnrichedPayment[];
  hidePaid: boolean;

  handleTogglePaid(payment: EnrichedPayment): void;

  handleArchivePayment(payment: EnrichedPayment): void;

  handleDeleteNote(payment: EnrichedPayment, noteId: number): void;

  handleAddNote(payment: EnrichedPayment, note: string): void;

  handleChangeCategory(payment: EnrichedPayment, category: string): void;

  classes: {
    table: string;
  };
}

interface PaymentsTableState {}

class PaymentsTable extends React.Component<
  PaymentsTableProps,
  PaymentsTableState
> {
  constructor(props: PaymentsTableProps) {
    super(props);
    autoBindReact(this);
  }

  renderDateHuman(date: DateTime): ReactElement {
    return (
      <span title={date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}>
        {date.toRelative()}
      </span>
    );
  }

  renderTableData() {
    let payments = this.props.payments;
    if (this.props.hidePaid) {
      payments = payments.filter((row: EnrichedPayment) => !row.paidThisPeriod);
    }

    if (payments.length === 0) {
      return (
        <TableRow>
          <TableCell align="center" colSpan={5} component="th" scope="row">
            No Data
          </TableCell>
        </TableRow>
      );
    }

    return payments.map((payment: EnrichedPayment) => (
      <PaymentsTableRow
        key={payment.id}
        payment={payment}
        handleTogglePaid={this.props.handleTogglePaid}
        handleArchivePayment={this.props.handleArchivePayment}
        handleAddNote={this.props.handleAddNote}
        handleDeleteNote={this.props.handleDeleteNote}
        handleChangeCategory={this.props.handleChangeCategory}
      />
    ));
  }

  render() {
    return (
      <TableContainer component={Paper}>
        <Table className={this.props.classes.table} stickyHeader>
          <TableHead>
            <TableRow>
              <Hidden xsDown>
                <TableCell style={{ minWidth: "22.5%" }}>Name</TableCell>
                <TableCell style={{ minWidth: "22.5%" }}>Amount</TableCell>
              </Hidden>
              <Hidden smUp>
                <TableCell colSpan={2} style={{ minWidth: "90%" }}>
                  Name
                </TableCell>
              </Hidden>
              <Hidden xsDown>
                <TableCell style={{ minWidth: "22.5%" }}>Next Due</TableCell>
                <TableCell style={{ minWidth: "22.5%" }}>Last Paid</TableCell>
              </Hidden>
              <TableCell style={{ minWidth: "10%" }}>Paid</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderTableData()}</TableBody>
        </Table>
      </TableContainer>
    );
  }
}

export default withTheme(withStyles(useStyles)(PaymentsTable));

import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import deepOrange from "@material-ui/core/colors/deepOrange";
import React from "react";
import { UserContext } from "./Providers/UserProvider";
import MainPage from "./Components/MainPage";
import SignIn from "./Components/SignIn";
import { SnackbarProvider } from "notistack";
import UserSettingRepository from "./Data/UserSettingRepository";
import UserSettings from "./Interfaces/UserSettings";
import { User } from "@firebase/auth-types";
import Loader from "./Components/Loader";
import { green } from "@material-ui/core/colors";
import autoBindReact from "auto-bind/react";

interface AppProps {
  swStatus: { status: number; payload: any };
}

interface AppState {}

class App extends React.Component<AppProps, AppState> {
  static contextType = UserContext;

  constructor(props: AppProps) {
    super(props);

    autoBindReact(this);
  }

  setDarkMode(enabled: boolean): Promise<void> {
    if (this.context) {
      const userSettings = this.context.userSettings;
      userSettings.prefersDarkMode = enabled;
      return this.handleUpdateUserSettings(userSettings);
    }
    return new Promise((resolve, reject) => {
      reject(new Error("Context not set"));
    });
  }

  handleUpdateUserSettings(userSettings: UserSettings): Promise<void> {
    return this.context.updateUserSettings(userSettings);
  }

  getUserPage(userSettings: UserSettings, user?: User | null) {
    if (user === undefined) {
      // loading, we don't know yet
      return <Loader />;
    }

    if (user === null) {
      // logged out
      return <SignIn />;
    }

    return (
      <SnackbarProvider maxSnack={3} autoHideDuration={3000}>
        <MainPage
          userSettings={userSettings}
          user={user}
          setDarkMode={this.setDarkMode}
          handleUpdateUserSettings={this.handleUpdateUserSettings}
          swStatus={this.props.swStatus}
        />
      </SnackbarProvider>
    );
  }

  render() {
    let userSettings = new UserSettingRepository().getDefault();
    if (this.context) {
      userSettings = this.context.userSettings;
    }

    const theme = responsiveFontSizes(
      createMuiTheme({
        palette: {
          type: userSettings.prefersDarkMode ? "dark" : "light",
          primary: green,
          secondary: deepOrange,
        },
        typography: {
          fontFamily: ["Raleway", "Helvetica", "Arial", "sans-serif"].join(","),
        },
      })
    );

    const user = this.context?.user;

    return (
      <ThemeProvider theme={theme}>
        {this.getUserPage(userSettings, user)}
      </ThemeProvider>
    );
  }
}

export default App;

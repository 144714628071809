import {
  NewReleasesRounded,
  ReceiptRounded,
  CreditCardRounded,
  AccountBalanceRounded,
  HouseRounded,
  BatteryChargingFullRounded,
  MonetizationOnRounded,
  InsertChartRounded,
  SvgIconComponent,
  RadioRounded,
  DirectionsCarRounded,
} from "@material-ui/icons";
import React from "react";
class CategoryRepository {
  categoryMap: { [id: string]: SvgIconComponent } = {
    default: NewReleasesRounded,
    bills: ReceiptRounded,
    savings: AccountBalanceRounded,
    debt: CreditCardRounded,
    mortgage: HouseRounded,
    utilities: BatteryChargingFullRounded,
    tax: MonetizationOnRounded,
    insurance: InsertChartRounded,
    entertainment: RadioRounded,
    car: DirectionsCarRounded,
  };

  getCategories(): string[] {
    return Object.keys(this.categoryMap);
  }

  getIcon(category: string): JSX.Element {
    category = category.toLowerCase();
    let Icon = this.categoryMap["default"];
    if (category in this.categoryMap) {
      Icon = this.categoryMap[category];
    }
    return React.createElement(Icon, {});
  }
}

export default CategoryRepository;

import React from "react";
import { firestore } from "../Firebase";
import { Button, createStyles, Grid, Hidden, Theme } from "@material-ui/core";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { User } from "@firebase/auth-types";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { GridRowData } from "@material-ui/data-grid";
import TextField from "@material-ui/core/TextField";
import withTheme from "@material-ui/core/styles/withTheme";
import withStyles from "@material-ui/core/styles/withStyles";
import SummaryCard from "./SummaryCard";
import Account from "../Interfaces/Account";
import EnrichedPayment from "../Interfaces/EnrichedPayment";
import Typography from "@material-ui/core/Typography";

import {
  OptionsObject,
  SnackbarKey,
  SnackbarMessage,
  withSnackbar,
} from "notistack";
import PaymentsTable from "./PaymentsTable/PaymentsTable";
import { Interval } from "luxon";
import OneOffPayment from "../Interfaces/OneOffPayment";
import autoBindReact from "auto-bind/react";
import UserSettings from "../Interfaces/UserSettings";

const useStyles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    content: {
      paddingTop: 0,
      textAlign: "left",
      overflowX: "auto",
      "& table": {
        marginBottom: 0,
      },
    },
    availableBalance: {
      "& input": {
        fontSize: "2em",
      },
    },
    subheading: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(1),
    },
  });

interface PaymentsProps {
  user: User;
  userSettings: UserSettings;
  account: Account;
  payments: EnrichedPayment[];
  oneOffPayments: { [timestamp: number]: OneOffPayment[] };
  interval: Interval;

  handleTogglePaid(payment: EnrichedPayment): void;

  handleTogglePaidOneOff(oneOffPayment: OneOffPayment): void;

  handleArchivePayment(payment: EnrichedPayment): void;

  handleDeleteNote(payment: EnrichedPayment, noteId: number): void;

  handleAddNote(payment: EnrichedPayment, note: string): void;

  handleChangeCategory(payment: EnrichedPayment, category: string): void;

  handleAddNewOneOffPayment(
    oneOffPayment: OneOffPayment
  ): Promise<OneOffPayment>;

  handleDeleteOneOffPayment(oneOffPayment: OneOffPayment): Promise<void>;

  classes: {
    root: string;
    content: string;
    availableBalance: string;
    subheading: string;
  };
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject
  ) => SnackbarKey;
  closeSnackbar: (key?: SnackbarKey) => void;
}

interface PaymentsState {
  hidePaid: boolean;
  availableBalance: number;
}

class Payments extends React.Component<PaymentsProps, PaymentsState> {
  constructor(props: PaymentsProps) {
    super(props);
    this.state = {
      hidePaid: true,
      availableBalance: this.props.account.availableBalance,
    };

    autoBindReact(this);
  }

  getFlatOneOffPayments(): OneOffPayment[] {
    let ret: OneOffPayment[] = [];

    // eslint-disable-next-line
    for (const [date, payments] of Object.entries(this.props.oneOffPayments)) {
      ret = ret.concat(payments);
    }

    return ret;
  }

  handleHidePaid(event: { target: { checked: boolean } }): void {
    this.setState({
      hidePaid: event.target.checked,
    });
  }

  handleUpdateAvailableBalance() {
    const availableBalanceRef = firestore
      .collection(`groups/${this.props.userSettings.group}/accounts`)
      .doc(this.props.account.id);

    return availableBalanceRef
      .update({
        availableBalance: this.props.account.availableBalance,
      })
      .then(() => {
        this.props.enqueueSnackbar("Account balance updated", {
          variant: "success",
        });
      });
  }

  handleChangeAvailableBalance(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      availableBalance: Number(event.target.value),
    });
    this.props.account.availableBalance = Number(event.target.value);
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Card>
          <CardHeader
            title=""
            subtitle=""
            action={
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.hidePaid}
                      onChange={this.handleHidePaid}
                      name="hide-paid"
                    />
                  }
                  label="Hide Paid"
                />
              </FormGroup>
            }
          />
          <CardContent className={classes.content}>
            <Typography
              variant="h5"
              color="primary"
              className={classes.subheading}
            >
              Recurring Payments (
              {
                this.props.payments.filter(
                  (row: GridRowData) => row.paidThisPeriod
                ).length
              }
              /{this.props.payments.length}
              Paid)
            </Typography>
            <PaymentsTable
              payments={this.props.payments}
              hidePaid={this.state.hidePaid}
              handleTogglePaid={this.props.handleTogglePaid}
              handleArchivePayment={this.props.handleArchivePayment}
              handleAddNote={this.props.handleAddNote}
              handleDeleteNote={this.props.handleDeleteNote}
              handleChangeCategory={this.props.handleChangeCategory}
            />
          </CardContent>

          <Grid
            container
            spacing={2}
            direction="row"
            justify="space-evenly"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} lg={12}>
              <SummaryCard title="Available Balance">
                <Grid
                  container
                  direction="row"
                  justify="flex-end"
                  alignItems="flex-end"
                >
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={1}
                  >
                    <Hidden xsDown>
                      <Grid item xs={10}>
                        <TextField
                          id="availableBalance"
                          type="number"
                          value={this.state.availableBalance}
                          onChange={this.handleChangeAvailableBalance}
                          fullWidth
                          className={classes.availableBalance}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          color="secondary"
                          size="large"
                          onClick={this.handleUpdateAvailableBalance}
                        >
                          Update
                        </Button>
                      </Grid>
                    </Hidden>
                    <Hidden smUp>
                      <Grid item xs={10}>
                        <TextField
                          id="availableBalance"
                          type="number"
                          value={this.state.availableBalance}
                          onChange={this.handleChangeAvailableBalance}
                          fullWidth
                          className={classes.availableBalance}
                        />
                        <Button
                          color="secondary"
                          size="large"
                          onClick={this.handleUpdateAvailableBalance}
                        >
                          Update
                        </Button>
                      </Grid>
                    </Hidden>
                  </Grid>
                </Grid>
              </SummaryCard>
            </Grid>
          </Grid>
        </Card>
      </div>
    );
  }
}

export default withSnackbar(withTheme(withStyles(useStyles)(Payments)));
